<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">SEARCH KAAGAPAY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-combobox
          class="mt-6"
          :append-icon="icons.mdiAccountSearchOutline"
          v-model="search"
          @keyup="searching($event.target.value)"
          @change="get_search_items_info"
          :items="search_items"
          item-value="id"
          item-text="name"
          placeholder="Search by (Account Name/Account #)"
          ref="memberCombobox"
          dense
          outlined
        ></v-combobox>
      </v-card-text>
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.title"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <account
            :cropped="cropped"
            :branch="branch"
            :data="kaagapay_information"
          ></account>
        </v-tab-item>
        <v-tab-item>
          <statement-of-account
            :data="payments_history"
            :kaagapay_info="kaagapay_information"
          ></statement-of-account>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
  import {
    mdiAccountOutline, mdiInformationOutline, mdiAccountCashOutline
    , mdiCash, mdiCellphone, mdiClipboardClockOutline, mdiContentSaveMoveOutline,
    mdiCashRefund, mdiShieldAccount, mdiGoogleCirclesCommunities, mdiBallotOutline
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import Account from './kaagapay_components/Account'
  import StatementOfAccount from './kaagapay_components/StatementOfAccount'

  export default {
    components: {
      Account,
      StatementOfAccount,
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/avatars/2.png'),
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiCash,
          mdiCellphone,
          mdiClipboardClockOutline,
          mdiContentSaveMoveOutline,
          mdiCashRefund,
          mdiShieldAccount,
          mdiGoogleCirclesCommunities,
          mdiBallotOutline,
        },
      }
    },
    data() {
      return {
        search: '',
        search_member: '',
        search_items: [],

        cropped: this.avatarImg,
        kaagapay_information: {},
        payments_history: [],

        tab: '',
        tabs: [
          {title: 'INFORMATIONS', icon: mdiAccountOutline},
          {title: 'STATEMENT OF ACCOUNT', icon: mdiAccountCashOutline},
        ],
      }
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member === '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch', 'branch_id', 'year', 'month_start', 'month_end', 'month_of', 'user_id', 'branch_address', 'branch_contact_no']),
    },
    methods: {
      ...mapActions('kaagapay', ['search_kaagapay_paid']),
      searching(value) {
        this.search_member = value
      },
      searching_search_items(value) {
        this.search_kaagapay_paid({
          branch_id: this.branch_id,
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      reset() {
        this.cropped = this.avatarImg
      },
      get_search_items_info() {
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(this.search.id)
          if (index != -1) {
            this.kaagapay_information = this.search_items[index].data
            this.payments_history = this.search_items[index].payments
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },
    }
  }
</script>
