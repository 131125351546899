<template>
  <div>
    <v-card
      flat
      class="pt-4"
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">STATEMENT OF ACCOUNT</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          @click="print_soa"
          v-if="!saving"
        >
          Print SOA
        </v-btn>
        <v-progress-circular
          :size=30
          :width="5"
          color="info"
          indeterminate
          v-else
        ></v-progress-circular>
      </v-toolbar>
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              OR Date
            </th>
            <th class="text-center text-uppercase">
              OR No
            </th>
            <th class="text-center text-uppercase">
              Amount
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td class="text-center">
              {{ item.or_date }}
            </td>
            <td class="text-center">
              {{ item.or_no }}
            </td>
            <td class="text-center">
              {{ item.amount }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
  import moment from 'moment'
  import {mapGetters} from "vuex";

  export default {
    props: {
      data: Array,
      room_data: Array,
      kaagapay_info: Object,
    },
    computed: {
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch', 'branch_id', 'year', 'month_start', 'month_end', 'month_of', 'user_id', 'branch_address', 'branch_contact_no']),
    },
    data() {
      return {
        saving: false,
      }
    },
    methods: {
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      print_soa() {
        var imgData = this.company_logo
        var payment = 0
        var payments_array = []
        payments_array.push(
          [
            {text: 'OR Date', alignment: 'center', style: 'label'},
            {text: 'OR No', alignment: 'center', style: 'label'},
            {text: 'Amount', alignment: 'center', style: 'label'},
          ]
        )
        if (this.data.length > 0) {
          this.data.forEach(function (item) {
            payment += item.amount
            var or_no = item.or_no
            var splitedOr = item.or_no.split('-')
            if (splitedOr.length > 0) {
              or_no = splitedOr[0]
            }
            payments_array.push(
              [
                {text: item.or_date, alignment: 'center'},
                {text: or_no, alignment: 'center'},
                {
                  text: (item.amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'center'
                },
              ]
            )
          })
        } else {
          payments_array.push(
            [
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE MEMORIAL CHAPEL CO.',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {text: 'Branch: ' + this.branch, style: 'main_info'},
                    {
                      text: 'STATEMENT OF ACCOUNT (SOA)', style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      }
                    },
                    {
                      text: 'KMF No.: ' + this.kaagapay_info.kmf_no, style: {
                        margin: [0, 2, 0, 2],
                        fontSize: 13,
                        alignment: 'right',
                        color: 'red',
                        bold: true,
                      }
                    },
                  ]
                },
              ]
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  style: 'main_info',
                  text: [
                    'Name of Kagaapay Member: ',
                    {
                      text: this.kaagapay_info.last_name + ', ' + this.kaagapay_info.first_name + ' ' + this.kaagapay_info.middle_name,
                      style: 'main_data'
                    }
                  ]
                },
              ]
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Gender: ',
                        {text: this.kaagapay_info.gender, style: 'main_data'}
                      ]
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Date of Birth: ',
                        {
                          text: moment(this.kaagapay_info.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                          style: 'main_data_2'
                        }
                      ]
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Age: ',
                        {
                          text: this.kaagapay_info.age, style: 'main_data'
                        }
                      ]
                    },
                  ]
                },
                {
                  style: 'main_info',
                  text: [
                    'Civil Status: ',
                    {
                      text: this.kaagapay_info.civil_status,
                      style: 'main_data'
                    }
                  ]
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Religion: ',
                        {
                          text: this.kaagapay_info.religion,
                          style: 'main_data'
                        }
                      ]
                    },
                  ]
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Occupation: ',
                        {
                          text: this.kaagapay_info.occupation,
                          style: 'main_data'
                        }
                      ]
                    },
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  style: 'main_info',
                  text: [
                    'Address: ',
                    {text: this.kaagapay_info.address, style: 'main_data'}
                  ]
                },
              ]
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      style: {fontSize: 15},
                      text: [
                        'Php Total: ',
                        {
                          text: this.formatPrice(this.kaagapay_info.payment),
                          style: {fontsize: 20, color: 'red', bold: true}
                        }
                      ]
                    },
                    {
                      style: {fontSize: 15},
                      text: [
                        'Status: ',
                        {
                          text: this.data.length > 0 ? 'GOOD' : 'PENDING PAYMENT',
                          style: {fontsize: 20, color: this.data.length > 0 ? 'blue' : 'red', bold: true}
                        }
                      ]
                    },
                  ]
                },]
            },
            {
              columns: [
                {
                  text: ' '
                },
              ]
            },
            'PAYMENT HISTORY',
            {
              table: {
                widths: [80, 80, 80, 200],
                body: payments_array,
                style: {fontSize: 9}
              },
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: this.kaagapay_info.chapel_manager
                    }
                  ]
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    }
                  ]
                },
                {
                  columns: [
                    {
                      text: 'CASHIER',
                    },
                    {
                      text: 'CHAPEL MANAGER',
                    }
                  ]
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' '
                    }
                  ]
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: '_________________________________________',
                    }
                  ]
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'AUDITOR',
                    },
                  ]
                },
              ]
            },
          ],
          footer: {
            // columns: [
            //     {
            //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
            //         alignment: 'center',
            //         style: 'tableExample'
            //     }
            // ]
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },


        }
        pdfMake.createPdf(docDefinition).open();
      },
    },
  }
</script>
