<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-text class="d-flex">
      <v-avatar rounded size="120" class="me-6">
        <v-img :src="cropped"></v-img>
      </v-avatar>
    </v-card-text>

    <v-card-text>
      <v-form ref="form" class="multi-col-validation mt-6">
        <v-row>
          <v-col md="4" cols="12">
            <v-text-field readonly v-model="branch" label="Branch" dense outlined></v-text-field>
          </v-col>
          <v-col md="4" cols="12">
            <v-text-field
              v-model="data.kmf_no"
              label="KMF No."
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col md="4" cols="12">
            <v-text-field
              readonly
              v-model="data.effective_date"
              label="Date of Service"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-card flat>
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col md="4" cols="12"></v-col>
              <v-row>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="data.last_name"
                    label="Last Name"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="data.first_name"
                    label="First Name"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="data.middle_name"
                    label="Middle Name"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="data.date_of_birth"
                    label="Date of Birth"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field v-model="data.age" label="Age" dense outlined
                                readonly></v-text-field>
                </v-col>

                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="data.religion"
                    dense
                    outlined
                    label="Religion"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="data.civil_status" label="Civil Status" readonly dense
                                outlined></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="data.gender" label="Gender" readonly dense
                                outlined></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="data.occupation" label="Occupation" readonly dense
                                outlined></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field v-model="data.address" label="Address" readonly dense
                                outlined></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-toolbar dense dark color="primary">
              <v-toolbar-title
              ><h4 class="font-weight-light">SALES AGENT INFORMATION</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col md="4" cols="12"></v-col>
              <v-row>
                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="data.payment"
                    label="Amount"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="data.sales_agent_id"
                    label="Sales Agent"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'

  export default {
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    props: {
      cropped: String,
      branch: String,
      data: Object,
    },
  }
</script>
